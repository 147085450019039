var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 pb-4"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"240px"}}),_c('th',{staticStyle:{"width":"240px"}},[_vm._v(" "+_vm._s(_vm.$t('Subject'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('Body'))+" ")]),_c('th')])]),_c('tbody',_vm._l((_vm.row.templates),function(serviceType){return _c('tr',{key:serviceType.notificationServiceType},[_c('td',{staticClass:"settings-notifications__cell-service-type"},[_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.notifications.NOTIFICATION_SERVICE_TYPE_LIST, serviceType.notificationServiceType ))+" ")]),_c('td',{staticClass:"settings-notifications__cell-template"},[_c('v-text-field',{attrs:{"value":serviceType.subject,"dense":"","hide-details":"","outlined":""},on:{"input":function($event){return _vm.changeTemplateSubject(
                serviceType.notificationType,
                serviceType.notificationServiceType,
                $event
              )}}})],1),_c('td',{staticClass:"settings-notifications__cell-template"},[_c('v-text-field',{attrs:{"value":serviceType.body,"dense":"","hide-details":"","outlined":""},on:{"input":function($event){return _vm.changeTemplateBody(
                serviceType.notificationType,
                serviceType.notificationServiceType,
                $event
              )}}})],1),(_vm.p2pAllowedButtons)?_c('td',{staticClass:"settings-notifications__cell-buttons"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.applyP2P(serviceType)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1):_c('td',{staticClass:"settings-notifications__cell-buttons"},[_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.isChanged(
                serviceType.notificationType,
                serviceType.notificationServiceType
              ),"color":"success","outlined":""},on:{"click":function($event){return _vm.apply(
                serviceType.notificationType,
                serviceType.notificationServiceType
              )}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.setDefault(
                serviceType.notificationType,
                serviceType.notificationServiceType
              )}}},[_vm._v(" "+_vm._s(_vm.$t('Set default'))+" ")])],1)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }