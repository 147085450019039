//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  props: { row: { type: Object, default: () => [] } },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
      loader: false,
      bodyToApply: null,
      subjectToApply: null,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('settingsNotifications', [
      'page',
      'pages',
      'data',
      'initialData',
      'defaultData',
      'filters',
      'sort',
    ]),

    p2pAllowedButtons() {
      return this.row.isP2P;
    },
  },

  methods: {
    ...mapMutations('settingsNotifications', {
      setNotifications: 'SET_DATA',
    }),
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('settingsNotifications', {
      updateNotificationTemplate: 'updateNotificationTemplate',
      updateP2PNotificationAction: 'updateP2PNotification',
      loadP2PLocalisationAction: 'loadP2PLocalisation',
      loadDataAction: 'loadData',
      loadInitialDataAction: 'loadInitialData',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      await this.loadP2PLocalisationAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    findTemplate(type, serviceType, data) {
      const findedType = data.find((e) => e.notificationType === type);
      if (!findedType || !findedType.templates) return null;
      const findedServiceType = findedType.templates.find((e) => (
        e.notificationServiceType === serviceType
      ));
      return findedServiceType;
    },

    changeTemplateBody(type, serviceType, value) {
      const data = JSON.parse(JSON.stringify(this.data));
      const changedServiceType = this.findTemplate(type, serviceType, data);
      changedServiceType.body = value;
      this.bodyToApply = changedServiceType.body;
      this.setNotifications(data);
    },

    changeTemplateSubject(type, serviceType, value) {
      const data = JSON.parse(JSON.stringify(this.data));
      const changedServiceType = this.findTemplate(type, serviceType, data);
      changedServiceType.subject = value;
      this.subjectToApply = changedServiceType.subject;
      this.setNotifications(data);
    },

    isChanged(type, serviceType) {
      const { data, initialData } = this;
      const currentServiceType = this.findTemplate(type, serviceType, data);
      const initialServiceType = this.findTemplate(type, serviceType, initialData);
      return (
        (currentServiceType.body !== initialServiceType.body)
        || (currentServiceType.subject !== initialServiceType.subject)
      );
    },

    async apply(type, serviceType) {
      const { data } = this;
      const { language } = this.filters;
      const { body, subject } = this.findTemplate(type, serviceType, data);
      const payload = {
        notificationType: type,
        notificationServiceType: serviceType,
        language,
        body,
        subject,
      };
      this.setGeneralProgress(true);
      try {
        await this.updateNotificationTemplate(payload);
        await this.loadInitialDataAction({});
        this.setSuccessNotification('Notification updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    setDefault(type, serviceType) {
      const { data, defaultData } = this;
      const currentServiceType = this.findTemplate(type, serviceType, data);
      const defaultServiceType = this.findTemplate(type, serviceType, defaultData);
      if (defaultServiceType) {
        currentServiceType.body = defaultServiceType.body;
      }
      const changedData = JSON.parse(JSON.stringify(data));
      this.setNotifications(changedData);
    },

    async applyP2P(service) {
      const { language } = this.filters;
      const payload = {
        id: service.id,
        language,
        alias: service.alias,
        subject: this.subjectToApply ? this.subjectToApply : service.subject,
        message: this.bodyToApply ? this.bodyToApply : service.body,
      };
      this.setGeneralProgress(true);
      try {
        await this.updateP2PNotificationAction(payload);
        this.setSuccessNotification('Notification updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
