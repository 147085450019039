//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import Filters from './SettingsNotificationsFilters.vue';
import Extra from './SettingsNotificationsExtra.vue';

import tableConfig from './tableConfig';
import constant from '~/const';
import checkConstAvailability from '~/utils/check-const-availability';

export default {
  components: { Filters, Extra },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('settingsNotifications', [
      'page',
      'pages',
      'data',
      'initialData',
      'defaultData',
      'filters',
      'sort',
    ]),

    dataComputed() {
      return this.data.filter((e) => !([
        constant.notifications.NOTIFICATION_TYPE.P2P_NOTIFICATION,
        constant.notifications.NOTIFICATION_TYPE.P2P_ARBITR_NOTIFICATION,
        constant.notifications.NOTIFICATION_TYPE.P2P_ADD_USER_TO_TRUSTED,
        constant.notifications.NOTIFICATION_TYPE.P2P_NEW_COMMENT,
        constant.notifications.NOTIFICATION_TYPE.P2P_ENABLE_LIMIT,
        constant.notifications.NOTIFICATION_TYPE.P2P_CHANGE_LIMIT,
        constant.notifications.NOTIFICATION_TYPE.P2P_CREATE_OFFER,
        constant.notifications.NOTIFICATION_TYPE.P2P_STATUS_OFFER,
        constant.notifications.NOTIFICATION_TYPE.P2P_START_TRADE,
        constant.notifications.NOTIFICATION_TYPE.P2P_CONFIRM_SEND_MONEY,
        constant.notifications.NOTIFICATION_TYPE.P2P_CONFIRM_RECEIVE_MONEY,
        constant.notifications.NOTIFICATION_TYPE.P2P_TRADE_RESULT,
        constant.notifications.NOTIFICATION_TYPE.P2P_TRADE_CLOSE,
        constant.notifications.NOTIFICATION_TYPE.P2P_TRADE_TERMINATED,
        constant.notifications.NOTIFICATION_TYPE.P2P_BLOCK_USER,
      ].includes(e.notificationType))).filter((e) => checkConstAvailability(e, { NOTIFICATION_TYPE: constant.notifications.NOTIFICATION_TYPE }, (item) => item.notificationType));
    },
  },

  methods: {
    ...mapMutations('settingsNotifications', {
      setNotifications: 'SET_DATA',
    }),
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('settingsNotifications', {
      loadDataAction: 'loadData',
      loadP2PLocalisationAction: 'loadP2PLocalisation',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      updateNotificationTemplate: 'updateNotificationTemplate',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      await this.loadP2PLocalisationAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },
  },
};
