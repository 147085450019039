//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        language: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('settingsNotifications', ['filterLists']),
    ...mapGetters('common', ['exchangeLanguageList']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    // this.setFilters({});
    const { id } = this.exchangeLanguageList[0];
    this.filters.language = id;
  },

  methods: {
    ...mapActions('settingsNotifications', ['setFilters']),
  },
};
