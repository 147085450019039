//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettingsNotifications from './SettingsNotifications/SettingsNotifications.vue';
import SettingsSystemNotifications from './SettingsSystemNotifications/SettingsSystemNotifications.vue';

export default {
  components: {
    SettingsNotifications,
    SettingsSystemNotifications,
  },
};
