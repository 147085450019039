var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters mobile-wrap"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"success","height":40,"outlined":""},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t('Create new notification'))+" ")])],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},scopedSlots:_vm._u([{key:"locales",fn:function(ref){
var row = ref.row;
return _vm._l((row.textList),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.language)+" ")])})}},{key:"isEnabled",fn:function(ref){
var row = ref.row;
return [(row.isEnabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_vm._e()]}},{key:"needAuth",fn:function(ref){
var row = ref.row;
return [(row.needAuth)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Need auth'))+" ")]):_vm._e()]}},{key:"color",fn:function(ref){
var row = ref.row;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"color":row.color,"dark":""}},[_vm._v(" "+_vm._s(row.color)+" ")])]}},{key:"dateUpdated",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateUpdated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateUpdated)))])])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")])]}}])})],1),_c('SettingsSystemNotificationsModal',{attrs:{"modal":_vm.editNotification.model,"is-creating":_vm.editNotification.isCreating},on:{"setColor":_vm.setColor,"input":_vm.closeModal,"addLocale":_vm.addLocaleTextList,"removeLocale":_vm.removeLocaleTextList,"localeUpdate":_vm.applyEditLocale,"cmsUpdate":_vm.apply},model:{value:(_vm.editNotification.modal),callback:function ($$v) {_vm.$set(_vm.editNotification, "modal", $$v)},expression:"editNotification.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }