//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './SettingsSystemNotificationsFilters.vue';
import SettingsSystemNotificationsModal from './SettingSystemNotificationsModal.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters, SettingsSystemNotificationsModal },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      stateLocalesModal: false,
      editNotification: {
        modal: false,
        isCreating: false,
        loading: false,
        model: {
          cmsId: '',
          source: '',
          version: 0,
          isEnabled: false,
          needAuth: false,
          color: '',
          textList: [],
        },
      },
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('settingsSystemNotifications', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('settingsSystemNotifications', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      updateSystemNotificationsAction: 'updateSystemNotifications',
      addSystemNotificationsAction: 'addSystemNotifications',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    closeModal() {
      this.editNotification.modal = false;
    },

    edit({
      cmsId,
      version,
      isEnabled,
      needAuth,
      color,
      name,
      textList,
      id,
    }) {
      this.editNotification.model = {
        id,
        cmsId,
        version,
        isEnabled,
        needAuth,
        color,
        name,
        textList,
      };
      this.editNotification.isCreating = false;
      this.editNotification.modal = true;
    },

    add() {
      this.editNotification.model = {
        cmsId: '',
        version: 1,
        isEnabled: true,
        needAuth: false,
        color: '',
        name: '',
        textList: [],
      };
      this.editNotification.isCreating = true;
      this.editNotification.modal = true;
    },

    setColor({ hex }) {
      this.editNotification.model.color = hex;
    },

    async apply() {
      const { model, isCreating } = this.editNotification;
      try {
        this.editNotification.loading = true;
        if (isCreating) {
          await this.addSystemNotificationsAction(model);
          this.setSuccessNotification('Notification created');
        } else {
          await this.updateSystemNotificationsAction(model);
          this.setSuccessNotification('Notification edited');
        }
        this.editNotification.loading = false;
        this.editNotification.modal = false;
        await this.loadDataAction();
      } catch (error) {
        this.editNotification.loading = false;
        this.setErrorNotification(error.message);
      }
    },

    addLocaleTextList(value) {
      const { model } = this.editNotification;
      if (Array.isArray(value)) {
        model.textList = value;
      } else {
        model.textList.push(value);
      }
    },

    removeLocaleTextList(value) {
      const { model } = this.editNotification;
      model.textList = value;
    },

    async applyEditLocale(textList) {
      this.editNotification.model.textList = textList;
      await this.apply();
    },
  },
};
