//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    modal: { type: Object, default: () => {} },
    value: { type: Boolean, default: false },
    isCreating: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      tab: 0,
    };
  },
  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['exchangeLanguageList']),
    addLocalesRules() {
      const { exchangeLanguageList, localesTextList } = this;
      return exchangeLanguageList.length > localesTextList.length;
    },
    exchangeLanguageListModifed() {
      return this.exchangeLanguageList.map((item) => {
        return {
          ...item,
          language: item.id,
        };
      });
    },
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    typesOfNotifications() {
      return [
        {
          id: 'text',
          caption: this.$t('TYPE IN TEXT MANUALLY'),
        },
        {
          id: 'cms',
          caption: this.$t('CHOOSE CMS ID'),
        },
      ];
    },

    localesTextList() {
      const { exchangeLanguageListModifed, modal } = this;
      if (modal.textList.length === 0) {
        return [{
          text: '',
          ...exchangeLanguageListModifed[0],
          language: exchangeLanguageListModifed[0].id,
        }];
      }

      return modal.textList.map((item) => {
        const language = exchangeLanguageListModifed
          .find(({ id }) => id.toLowerCase() === item.language);
        return { ...language, ...item };
      });
    },
  },

  watch: {
    modal: {
      handler(data) {
        this.tab = !this.isCreating && data.name === null ? 1 : 0;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    setColor(value) {
      this.$emit('setColor', value);
    },
    addNewLocale() {
      const { exchangeLanguageListModifed, localesTextList, isCreating } = this;
      const availableLanguages = exchangeLanguageListModifed.filter((language) => {
        const list = localesTextList.map((x) => x.id.toLowerCase());
        return !list.includes(language.id.toLowerCase());
      });
      const firstAvailableLanguage = {
        ...availableLanguages[0],
        text: '',
      };
      if (isCreating) {
        this.$emit('addLocale', [...localesTextList, firstAvailableLanguage]);
      } else {
        this.$emit('addLocale', firstAvailableLanguage);
      }
    },
    removeLocale(id) {
      const { localesTextList } = this;
      const filteredTextList = localesTextList.filter((locale) => {
        return locale.id !== id;
      });
      this.$emit('removeLocale', filteredTextList);
    },

    applyEditLocale() {
      const { localesTextList } = this;
      const modifyForRequest = localesTextList.map((locale) => {
        return {
          language: locale.id,
          text: locale.text,
          id: locale.id,
        };
      });
      this.$emit('localeUpdate', modifyForRequest);
    },

    apllyEditCMS() {
      this.$emit('cmsUpdate');
    },
  },
};
