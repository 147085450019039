export default [
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Source',
    },
    cell: {
      type: 'text',
      value: (e) => e.cmsId || e.name,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Version',
    },
    cell: {
      type: 'text',
      value: (e) => e.version,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Locales',
    },
    cell: {
      type: 'slot',
      name: 'locales',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Enabled',
    },
    cell: {
      type: 'slot',
      name: 'isEnabled',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Need auth',
    },
    cell: {
      type: 'slot',
      name: 'needAuth',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Color',
    },
    cell: {
      type: 'slot',
      name: 'color',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Date updated',
    },
    cell: {
      type: 'slot',
      name: 'dateUpdated',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
