var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-notifications"},[_c('div',{staticClass:"settings-notifications__filters filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"settings-notifications__table"},[_c('UiTable',{attrs:{"data":_vm.dataComputed,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"to-set":function (e) { return e.notificationType; },"fixed-header":"","height":"657px","show-extra":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.notifications.NOTIFICATION_TYPE_LIST, row.notificationType ))+" ")]}},{key:"text",fn:function(ref){
var row = ref.row;
return [(row.templates && row.templates.length)?[_vm._v(" "+_vm._s(row.templates[0].body)+" ")]:_vm._e()]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [(row.notificationType === _vm.constant.notifications.NOTIFICATION_TYPE.P2P_NOTIFICATION_AND_LOCALISATION)?_c('UiTable',{attrs:{"data":row.items,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"to-set":function (e) { return e.alias; },"fixed-header":"","height":"527px","show-extra":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.alias)+" ")]}},{key:"text",fn:function(ref){
var row = ref.row;
return [(row.templates && row.templates.length)?[_vm._v(" "+_vm._s(row.templates[0].body)+" ")]:_vm._e()]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"row":row}})]}}],null,true)}):_c('Extra',{attrs:{"row":row}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }