export default [
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'slot',
      name: 'name',
    },
  },
  {
    width: 75,
    header: {
      type: 'text',
      caption: 'Text',
    },
    cell: {
      type: 'slot',
      name: 'text',
    },
  },
];
